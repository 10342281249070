.blur-in {
  animation: blur 7s ease-out;
  opacity: 1;
  margin: 0;
  user-select: none;
}

.fade-in {
  animation: fadein 3s ease-in-out;
}
.fade-in-10 {
  animation: fadein70 7s ease-in-out;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein70 {
  0% {
    opacity: 0;
    box-shadow: inset 0 0 0 0 white;
  }
  70% {
    opacity: 0;
    box-shadow: inset 0 0 0 0 white;
  }
  100% {
    opacity: 1;
    box-shadow: inset 0 0 30px 40px white;
  }
}

@keyframes blur {
  0% {
    text-shadow: 0 0 95px #fff;
    opacity: 0;
  }

  5% {
    text-shadow: 0 0 70px #fff;
  }

  15% {
    opacity: 1;
  }

  20% {
    text-shadow: 0 0 0 #fff;
  }

  100% {
    text-shadow: 0 0 0 #fff;
  }
}
