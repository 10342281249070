@import url("https://fonts.googleapis.com/css?family=Roboto|Roboto:100|Rubik+Mono+One&display=swap");

html,
body {
  height: 100%;
  overflow: hidden;
  transform: translateZ(0px);
}

#root {
  transform-style: inherit;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  width: 100vw;
  max-width: 100%;
  perspective: 1px;
  transform-style: preserve-3d;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rubik {
  font-family: "Rubik Mono One", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.parallax-wrapper {
  width: 100%;
  box-sizing: border-box;
  transform-style: preserve-3d;
}

.parallax-wrapper::before {
  content: "";
  width: calc(100% + 10px);
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateZ(-1px) scale(2);
  top: 0;
  right: 0;
  bottom: 0;
  left: -10px;
}

.landing-parallax-wrapper {
  height: calc(100vh + 250px);
}

.landing-parallax-wrapper::before {
  animation: fadein 3s ease-in-out;
  height: calc(100vh + 250px);
  background-image: radial-gradient(circle, transparent 0%, black 100%),
    linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../media/tuukka.jpg");
  background-position: 45% 0;
}

.reference-parallax-wrapper {
  height: 100vh;
  box-sizing: border-box;
  transform-style: preserve-3d;
}

.reference-parallax-wrapper::before {
  height: 100vh;
  background-image: radial-gradient(circle, transparent 0%, black 100%),
    linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../media/tuukka2.jpg");
  background-position: 65% 0;
}

.wip {
  width: 100%;
  height: 500px;
  box-sizing: border-box;
  transform-style: preserve-3d;
}
